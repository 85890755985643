export const data = [
  {
    text: 'Давно шукала такий браслет. Купила також на подарунок подрузі, сподіваюсь їй сподобається)',
    auctor: 'Олена, 1 січня 2023',
  },

  {
    text: 'Я отримала ниті, за що вам дуже вдячна! Нехай вас Святий Благословить, здоров’я посилає, миру і добра.',
    auctor: 'Іванна, 12 грудня 2022',
  },

  {
    text: 'Дякую за оперативну доставку! Чудовий продавець, все розповіла докладно про нитки. Рекомендую!',
    auctor: 'Наталія, 23 листопада 2022',
  },
  {
    text: 'Давно шукала такий браслет. Купила також на подарунок подрузі, сподіваюсь їй сподобається)',
    auctor: 'Олена, 1 січня 2023',
  },

  {
    text: 'Я отримала ниті, за що вам дуже вдячна! Нехай вас Святий Благословить, здоров’я посилає, миру і добра.',
    auctor: 'Іванна, 12 грудня 2022',
  },

  {
    text: 'Дякую за оперативну доставку! Чудовий продавець, все розповіла докладно про нитки. Рекомендую!',
    auctor: 'Наталія, 23 листопада 2022',
  },
];
